import React from 'react';
import './FormTitle.scss';

export interface FormTitleProps {
    title: string;
    subTitle?: string;   
    backgroundColor?: any;  
}
const FormTitle = ({ title, subTitle, backgroundColor }: FormTitleProps, props: any) => {

    return (
        <div className="title-container" style={backgroundColor}>

        <div className="form-title" style={backgroundColor}>
            <h2 className="title">{title}</h2>
        </div>

        { subTitle &&
            <div className="form-subtitle" style={backgroundColor}>
                <h2 className="subtitle">{subTitle}</h2>
            </div>
        }
        

        </div>
    )
}

export default FormTitle;