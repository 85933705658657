import { availableMoveSizes, availableMoveTypes, availableMoveSizeDetails, availableMoveSizeDetailsApartment } from "./data";
import moment from "moment";

var today = moment().format("MM/DD/YYYY");
var tomorrow = moment().add(1, 'days').format("MM/DD/YYYY");
//("XYZ------------"+today);

export const moveWithFormFields = (lead:any, couponDates: any[] = []) => {
    return {
        "formPages": [
      
            {
                "id": "",
                "title": "We're moving your...",
                "key": "type",
                "shortTitle": "",
                "goNextOnValid": true,
                "fields": [
                    {
                        "fieldType": "singleSelect",
                        "required": true,
                        "formControlName": "MoveSize",
                        "value": "",
                        "bindLabel": "value",
                        "bindValue": "value",
                        "options": availableMoveSizes
                    },
                ]
            },
            {
                "id": "",
                "title": "What size is your move?",
                "key": "size",
                "shortTitle": "",
                "goNextOnValid": true,
                "fields": [
                    {
                        "fieldType": "singleSelect",
                        "required": true,
                        "formControlName": "MoveSizeDetail",
                        "value": "",
                        "bindLabel": "value",
                        "bindValue": "value",
                        "options": availableMoveSizeDetails
                    },
                ]
            },
            {
                "id": "",
                "title": "How far are we moving you?",
                "key": "move",
                "shortTitle": "",
                "goNextOnValid": true,
                "fields": [
                    {
                        "fieldType": "singleSelect",
                        "required": true,
                        "formControlName": "MoveType",
                        "value": "",
                        "bindLabel": "label",
                        "bindValue": "value",
                        "options": availableMoveTypes
                    },
                ]
            },
            // {
            //     "id": "",
            //     "title": "What is your current Zip?",
            //     "key": "zip",
            //     "shortTitle": "",
            //     "goNextOnValid":false,
            //     "fields": [
            //         {
            //             "label": "Zip Code",
            //             "fieldType": "textField",
            //             "required": true,
            //             "formControlName": "Zipcode",
            //             "keyboardType": "tel",
            //             "validators": {
            //                 // Regex for USA phone pattern validation
            //                 "custom": /(^\d{5}$)|(^\d{5}-\d{4}$)/
            //             },
            //             "value": "",
            //             "order": 1
            //         },
            //         {
            //             "label": "Continue",
            //             "fieldType": "button",
            //             "formControlName": "Zipdone",
            //             "order": 2
            //         },
            //     ]
            // },
            {
                "id": "",
                // "title": "Where from and to?",
                "key": "zip",
                "shortTitle": "",
                "goNextOnValid":true,
                "fields": [
                    {
                        "label": "Zip Code",
                        "fieldType": "zipcode",
                        "required": false,
                        "formControlName": "duplicateZipcode",
                        "keyboardType": "number",
                        // "validators": {
                        //     // Regex for USA phone pattern validation
                        //     "custom": /(^\d{5}$)|(^\d{5}-\d{4}$)/
                        // },
                        "value": "",
                        "order": 1
                    },
                    // {
                    //     "label": "Continue",
                    //     "fieldType": "button",
                    //     "formControlName": "Zipdone",
                    //     "order": 2
                    // },
                ]
            },
            {
                "id": "",
                "title": "Approximate Move Date",
                "subTitle": "You can easily change this later.",
                "key": "date",
                "shortTitle": "",
                "goNextOnValid": true,
                "footer": true,
                "fields": [
                    {
                        "fieldType": "datePicker",
                        // "required": true,
                        "formControlName": "MoveDate",
                        "value": ""
                    },
                    // {
                    //     "fieldType": "singleSelect",
                    //     // "required": true,
                    //     "formControlName": "MoveDate",
                    //     "value": lead.MoveDate,
                    //     "bindLabel": "label",
                    //     "bindValue": "value",
                    //     "options": [
                    //         /* { "id": "", "label": "TODAY", "value": today },
                    //         { "id": "", "label": "TOMORROW", "value": tomorrow  }, */
                    //         { "id": "", "label": "I'M NOT SURE", "value": false }
                    //       ]
                    // },
                ]
            },
            {
                "id": "",
                "title": "Receive Your Personalized Quote",
                "key": "confirm",
                "shortTitle": "",
                // "footerbtn": true,
                "fields": [
                    {
                        // "label": "Name",
                        "fieldType": "couponInfo",
                        // "required": true,
                        // "formControlName": "FirstName",
                        // "value": lead.FirstName,
                        "order": 1,
                        // isVisible: () => couponDates.indexOf(moment(lead.MoveDate).format('MM-DD-YYYY')) > -1
                    },
                    {
                        "label": "First Name",
                        "fieldType": "textField",
                        "required": true,
                        "formControlName": "FirstName",
                        "value": "",
                        "order": 2
                    },

                    {
                        "label": "Phone",
                        "fieldType": "textField",
                        "required": true,
                        "formControlName": "Telephone",
                        "keyboardType": "tel",
                        "validators": {
                            // Regex for USA phone pattern validation
                            "custom": /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/
                        },
                        "mask": ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                        "value": "",
                        "order": 3
                    },
                    {
                        "label": "CONTINUE",
                        "fieldType": "button",
                        "formControlName": "done",
                        // "keyboardType": "tel",
                        // "mask": ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                        // "value": "",
                        "order": 4
                    },
                ]
            }
        ]
    }
}
