import React, { Component } from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import { FormControl } from 'react-reactive-form';
import './CountdownTimer.scss';

export interface CountdownTimerProps {
	formControl?: FormControl;
	EmployeeImg?: any;
}

export interface CountdownTimerState {
	// EmployeeImg: any;

}


class CountdownTimer extends React.Component<CountdownTimerProps, CountdownTimerState> {
	constructor(props: any) {
		super(props);
		this.state = {
			EmployeeImg: ""

		};
	}
	componentDidMount() {
		// debugger;
		// this.setState({ EmployeeImg: localStorage.getItem("EmployeeImg") })
	}
	render() {
		return (




					<div className="photo-wrapper">
					{/* Note: The countdown timer is currently hard-coded to a duration of 15 seconds.
						If this time needs to change,
						go to CountdownTimer.scss and change the animation duration for this class: .progress-ring_circle
						 */}

						<svg
							className="progress-ring"
							xmlns="http://www.w3.org/2000/svg" version="1.1"
							viewBox="0 0 120 120"
							preserveAspectRatio="xMidYMid meet"
							width="100%">

							<g id="ring-group" transform="rotate(-90 60 60)">
								<circle
									className="progress-ring_bg"
									stroke="#bae3d7"
									strokeWidth="5"
									fill="transparent"
									r="56"
									cx="60"
									cy="60" />
								<circle
									className="progress-ring_circle"
									stroke="#00945D"
									strokeWidth="5"
									fill="transparent"
									r="56"
									cx="60"
									cy="60"

								/>
							</g>
						</svg>

						<div className="photo-circle">
							{!!this.props.EmployeeImg
								? <img className="photo" src={this.props.EmployeeImg.toString() } alt="Your Moving Consultant" />
								: <img className="photo" src={require("assets/images/1-Estimate-small.png")} alt="Your Moving Consultant" />}

						</div>

					</div>



		)

	}
}

export default CountdownTimer;
