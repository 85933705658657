import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss';
// import { Match, navigate } from '@reach/router'
import { Router, Link, navigate, Match, Location } from "@reach/router";
import { Dialog } from '@reach/dialog';
import create from 'zustand';
import 'fonts/DINPro-Regular.woff';
import List from '././screens/list/List';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import './theme/styles/styles.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { MoveWithForm } from 'screens/moveWithForm';
import { Thankyou } from 'screens/thankyou';
import { NVLThankyouScreen } from 'screens/nvlThankyouScreen';
import { Login } from 'screens/login';
import { SignUp } from 'screens/signup';
import { PopupScreen } from 'screens/popUpScreen';
import { getEstimatorDetails } from 'core/services/movewithService'
//import { getFormPages } from 'core/services/graphqlService'

import '@reach/dialog/styles.css';
import { Coupon } from 'screens/coupon';
import ApolloClient from 'apollo-boost';
//@ts-ignore
//import { ApolloProvider } from '@apollo/react-hooks';

declare var window: any;
// We just use a store to augment the router navigation function so that it
// handles transition status and disable the body scroll during the transition
// (ie animation).

const [useStore] = create(set => ({
  status: 'idle',
  navigate: (address: any, domEl: any, state?: any) => {
    set({ status: 'transitioning' })
    if (domEl) disableBodyScroll(domEl)
    navigate(address, state)
  },
  endNav: () => {
    set({ status: 'idle' })
    clearAllBodyScrollLocks()
  }
}))

export { useStore }

function Routes(props: any) {
  return (
    <Router {...props}>
      <List path="/" />
      <List path="/:index">
        {/* <PopupScreen path="/popup" /> */}
      </List>
      <PopupScreen path="/popup" />
      <Thankyou path="/thanks" />
      <NVLThankyouScreen path="/nvlthanks" />
      <Login path="/login"></Login>
      <SignUp path="/signup"></SignUp>
      <Coupon path="/coupon" />
    </Router>
  );
}
export const client = new ApolloClient({
 // uri: 'https://nvlservice-dev.us-east-1.elasticbeanstalk.com/graphql/'
 //uri:"https://allmysonsnvl.allmysonsstagingbeanstalk.com/graphql/" // staging
 uri: "https://nvlprod.allmysonsbeanstalk.com/graphql/"
  //,//Production
// uri:endpoints.graphql
});
//@ts-ignore
const App = ({ children: any }) => {

 

  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    localStorage.clear();
    const params = new URLSearchParams(window.location.search);
		const phone = params.get('phone') || "8773507796"; //
		// alert(phone)
		const Src = params.get('src') || "GA"; 
		localStorage.setItem("Src", Src);
		localStorage.setItem("phone",phone)
		const branchID =  params.get('branch') || "9";
    localStorage.setItem("branchID", branchID);
    localStorage.setItem("coupon","")
    getEstimatorDetails(branchID)
    var url = window.location.href;
    localStorage.setItem("url", url)
    // console.log(window.ip)
    localStorage.setItem("ip", window.ip)
    localStorage.setItem("amsPh","8442070741")
    localStorage.setItem("office", "NVL")
  //  getFormPages();
  }, []);

  return (
    <Location>
      {({ location, navigate }) => {
        //@ts-ignore
        const { oldLocation } = location.state || {};
        return (
          < >
            <Routes location={oldLocation != null ? oldLocation : location}/>
            <Dialog
              style={{zIndex: 9500}}
              isOpen={oldLocation != null}
              onDismiss={() => {
                navigate(oldLocation.pathname);
              }}
            >
              <Routes location={location} />
            </Dialog>
          </>
        );
      }}
    </Location>
  )
};



const rootElement = document.getElementById('root')
//@ts-ignore
ReactDOM.render(<App />, rootElement)
