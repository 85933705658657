import React from "react";
import './CardLayout.scss';
// import { CardPaginator } from "components/cardPaginator";
// import { Shimmer } from "components/shimmer";

export interface CardLayoutProps {
    children?: any;
    paginator?: { totalCount?: number, pageSize: number } | null;
    paginatorText?: string;
    expand?: boolean;
    iconName?: any;
    loading?: boolean;
    onClick?(): void; // on card click as a whole
    onExpand?(): void; // on paginator expand
    style?: any;
    infoText?: any;
}

export interface CardLayoutState {

}

class CardLayout extends React.Component<CardLayoutProps, CardLayoutState> {
    static defaultProps = {
        iconName: "fa fa-expand"
    }
    constructor(props: CardLayoutProps) {
        super(props);
        this.state = {};
    }
    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();

        }
    }

    handleExpand() {
        if (this.props.onExpand) {
            this.props.onExpand();
        }
    }

    render() {
        return (
            <div className="card-container" onClick={() => this.handleClick()} style={{...this.props.style}}>
            
                {this.props.children}

            </div>
        );
    }
}

export default CardLayout;