import React from "react";
import "./ZipCode.scss"
import { TextField } from "components/textField";
import { Button } from "components/button";
import "./ZipCode.scss"
import { FormGroup, Validators, FormBuilder, FormControl } from "react-reactive-form";
import { navigate } from "@reach/router";

declare global {
    interface Window { dataLayer: any; }
    interface Window { dataLayer: any; }
}
window.dataLayer = window.dataLayer || {};
window.fbq = window.fbq || {}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

export interface ZipCodeProps {
    formControl?: FormControl;
    onClick?(): void;
}

export interface ZipCodeState {
    disabled: boolean
    isValid: boolean
    movingToZipCode: boolean
    movingFromZipCode: boolean
    type: string
}

class ZipCode extends React.Component<ZipCodeProps, ZipCodeState> {
    formGroup1: FormGroup = FormBuilder.group(
        {
            origin: [''],
            onBookMove: ['']
        }
    );
    formGroup2: FormGroup = FormBuilder.group(
        {
            origin: ['', Validators.required],
            destination: ['', Validators.required],
            onBookMove: ['']
        }
    );
    constructor(props: ZipCodeProps) {
        super(props);
        this.state = {
            disabled: true,
            isValid: false,
            movingToZipCode: false,
            movingFromZipCode: false,
            type: "tel",
        };
        //this.onFormChange = this.onFormChange.bind(this);


        this.onValueChanges = this.onValueChanges.bind(this);
     //   this.onValueChanges1 = this.onValueChanges1.bind(this);
        this.formGroup1.valueChanges.subscribe(this.onValueChanges)
        this.formGroup2.valueChanges.subscribe(this.onValueChanges)
    }

    onValueChanges1(value: any) {
        localStorage.setItem("origin", value.origin)
            localStorage.setItem("destination", value.destination)
            if (this.formGroup2.valid && !this.state.isValid) {
                this.setState({ isValid: true });
            }
            else if (!this.formGroup2.valid && this.state.isValid) {
                this.setState({ isValid: false });
            }

    }
    onValueChanges(value: any) {
        if(value.origin1)
                 localStorage.setItem("origin1", value.origin1)
        if(value.origin)
        localStorage.setItem("origin", value.origin)
        if(value.destination)
        localStorage.setItem("destination", value.destination)
        
        if (this.formGroup1.valid && !this.state.isValid) {
            this.setState({ isValid: true });
        }
        else if (!this.formGroup1.valid && this.state.isValid) {
            this.setState({ isValid: false });
        }
        if (this.formGroup2.valid && !this.state.isValid) {
            this.setState({ isValid: true });
        }
        else if (!this.formGroup2.valid && this.state.isValid) {
            this.setState({ isValid: false });
        }

      
    }
    /*   onFormChange(value: any) {
          console.log(value);
         
          if (this.formGroup.valid && this.state.disabled) {
              this.setState({ disabled: false });
          } else {
              this.setState({ disabled: true });
          }
      } */
    handleClick() {
        //  alert("click me")
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    onKeyPress() {
        //  alert("click me")
        const {formControl, onClick} = this.props;
        const { movingFromZipCode, movingToZipCode } = this.state;
        let isNextPageAllowed = true;
        if(formControl){
            const value = formControl.parent.value;
            if (value.MoveType === "LongDistance" || value.MoveType === "ShortHaul"){
                isNextPageAllowed = (movingFromZipCode === true && movingToZipCode === true);
            }
            if (value.MoveType === "Local") {
                isNextPageAllowed = movingFromZipCode === true;
            }
        }
        if (onClick && isNextPageAllowed) {
            // this.setState({
            //     type: 'hidden'
            // });
            onClick();
        }
    }

    componentWillUnmount() {
        this.formGroup1.valueChanges.unsubscribe(this.onValueChanges);
        this.formGroup2.valueChanges.unsubscribe(this.onValueChanges);
    }

   componentDidMount(){
        window.dataLayer.push({
            event: 'pageview',
            page: { path: "/zip" }
        })
    } 
       onChange = (value:any, label: string) => {
        if (label === 'Moving From Zipcode' && value !== '' && value.length === 5){
            this.setState({
                movingFromZipCode: true
            })
        }
        if (label === 'Moving From Zipcode' && (value === '' || value.length != 5)) {
            this.setState({
                movingFromZipCode: false
            })
        }
        if (label === 'Moving to Zipcode' && value !== '' && value.length === 5) {
            this.setState({
                movingToZipCode: true
            })
        }
        if (label === 'Moving to Zipcode' && (value === '' || value.length != 5)) {
            this.setState({
                movingToZipCode: false
            })
        }
    }
    onEnter() {
        window.scrollTo(0, 0);
    }
    render() {
        var isNVL = localStorage.getItem("isNVL");
        const {movingFromZipCode , movingToZipCode} = this.state;
        return (
            <div>
                {
                    isNVL === "0" ?
                        <div className="zipcode-container">
                            <div className="zipcode-title">
                                
                                    
                                    <h1>What is your current zipcode?</h1>
                                    
                                
                                
                            </div>
                            <div className="text-fields">
                                <div className="move-zipcode">
                                    <TextField
                                        label={'Moving From Zipcode'}
                                        keyboardType={this.state.type}
                                        formControl={this.formGroup1.get("origin") as FormControl}
                                        onChange={this.onChange}
                                        onFocusOut={this.onEnter}
                                        showCustomError={true}
                                        isZipCode={true}
                                        onKeyPress={() => this.onKeyPress()}
                                        
                                />
                                </div>                              
                            </div>
                            <div className="continue-btn">
                                    <Button
                                        text={'Continue'}
                                        formControl={this.formGroup1.get('onBookMove') as FormControl}
                                        onClick={() => this.handleClick()}
                                        formGroup={this.formGroup1}
                                        disabled={!(movingFromZipCode === true)}
                                        
                                    />
                                </div>
                        </div>
                        :
                        <div className="zipcode-container">
                            <div className="zipcode-title">
                            <h1>Moving from and to?</h1>
                            </div>
                            <div className="text-fields">
                                <div className="move-zipcode">
                                    <TextField
                                        label={'Moving From Zipcode'}
                                        keyboardType={this.state.type}
                                        formControl={this.formGroup2.get("origin") as FormControl}
                                         onChange={this.onChange}
                                         onFocusOut={this.onEnter}
                                        showCustomError={true}
                                        isZipCode={true}
                                        onKeyPress={() => this.onKeyPress()}
                                    />
                                    <TextField
                                        label={'Moving to Zipcode'}
                                        keyboardType={this.state.type}
                                        formControl={this.formGroup2.get("destination") as FormControl}
                                        onChange={ this.onChange}
                                        onFocusOut={this.onEnter}
                                        showCustomError={true}
                                        isZipCode={true}
                                        onKeyPress={() => this.onKeyPress()}
                                    />
                                </div>
                               
                            </div>
                            <div className="continue-btn">
                                    <Button
                                        text={'Continue'}
                                        formControl={this.formGroup2.get('onBookMove') as FormControl}
                                        onClick={() => this.handleClick()}
                                        formGroup={this.formGroup2}
                                        disabled={!(movingFromZipCode === true && movingToZipCode === true)}
                                    />
                                </div>
                        </div>
                }
            </div>

        );
    }
}

export default ZipCode;