import React from "react";
import { CardLayout } from "components/cardLayout";
import { CountdownTimer } from '../../components/CountdownTimer'
//@ts-ignore
import StarRatingComponent from 'react-star-rating-component';
import "./ThankyouCard.scss"

export interface ThankyouCardProps {
    rating?: any;
    logoImg?: any;
    moverInfo?: any;
    avatarImg?: any;
    smallText?: any;
    EmployeeImg?: any;
    rating_half_star?: any;
    bottomstarRating?: any;
    topStarRating?: any;
}

export interface ThankyouCardState {
    rating_half_star: any;
    rating: any;
}

class ThankyouCard extends React.Component<ThankyouCardProps, ThankyouCardState> {
    constructor(props: ThankyouCardProps) {
        super(props);
        this.state = {
            rating: 1,
            rating_half_star: 4.5,
        };
    }
    onStarClick(nextValue: any, prevValue:any, name:any) {
        this.setState({rating: nextValue});
      }
      onStarClickHalfStar(nextValue: any, prevValue: any, name:any, e:any) {
        const xPos = (e.pageX - e.currentTarget.getBoundingClientRect().left) / e.currentTarget.offsetWidth;
    
        if (xPos <= 0.5) {
          nextValue -= 0.5;
        }
    
        console.log('name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue);
        // console.log(e);
        this.setState({rating_half_star: nextValue});
      }
    render() {
        const { rating_half_star } = this.state;
        return (
            <CardLayout style={{marginTop:"0"}}>
                <div className="card-logo">
                    <img src={this.props.logoImg} />
                </div>
            {this.props.topStarRating &&
            <div className="rating-star">
                <StarRatingComponent
                    name="rate1"
                    starCount={5}
                    value={this.state.rating_half_star}
                    // onStarClick={this.onStarClick.bind(this)}
                    onStarClick={this.onStarClickHalfStar.bind(this)}
                    emptyStarColor={'#F2994A'}
                    starColor={'#F2994A'}
                    editing={false}
                    renderStarIcon={(index:any, value:any) => {
                        return (
                            <span>
                            <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                            </span>
                        );
                        }}
                        renderStarIconHalf={() => {
                        return (
                            <span>
                            <span style={{position: 'absolute'}}><i className="far fa-star" /></span>
                            <span><i className="fas fa-star-half" /></span>
                            </span>
                        );
                        }}
                />
            </div>}
                <div className="small-text">
                    <p>{this.props.moverInfo}</p>
                </div>
                <div className="avatar-img">
                   {this.props.avatarImg ?
                        (<img src={this.props.avatarImg}/>)
                        :
                    (<CountdownTimer
                    EmployeeImg={this.props.EmployeeImg.toString()}
                    />)
                    }
                </div>
                {this.props.bottomstarRating &&
                <div className="rating-star-bottom">
                    <StarRatingComponent
                        name="rate1"
                        starCount={5}
                        value={this.state.rating_half_star}
                        // onStarClick={this.onStarClick.bind(this)}
                        onStarClick={this.onStarClickHalfStar.bind(this)}
                        emptyStarColor={'#F2994A'}
                        starColor={'#F2994A'}
                        editing={false}
                        renderStarIcon={(index:any, value:any) => {
                            return (
                              <span>
                                <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                              </span>
                            );
                          }}
                          renderStarIconHalf={() => {
                            return (
                              <span>
                                <span style={{position: 'absolute'}}><i className="far fa-star" /></span>
                                <span><i className="fas fa-star-half" /></span>
                              </span>
                            );
                          }}
                    />
                </div>
            }
                <div className="small-text">
                <p>{this.props.smallText}</p>
                </div>
            </CardLayout>
        );
    }
}

export default ThankyouCard;