import { IFormPage, FormPage, IForm, IField } from "./form";

export interface IOption {
    id?: string;
    label: string;
    value: string;
    icon: string;
    selected: boolean;
    subFormPages?: IFormPage[];
    form: IForm;
    [name: string]: any;
    readonly bindValue: any;
    readonly bindLabel: any;
    
    onTap(): void;
    removeSubPages(): void;
    addSubPages(): void;
}

export class Option implements IOption {
    id?: string;
    // TODO Remove label & value fields. We are populating dynamic fields for any keys/data
    label: string;
    value: string;
    icon: string;

    [name: string]: any;
    selected: boolean = false;
    subFormPages?: IFormPage[] = [];

    field: IField;
    formPage: IFormPage;
    form: IForm;

    constructor(props: IOption) {
        this.id = props.id;
        this.label = props.label;
        this.value = props.value;
        this.icon = props.icon;
        // this.selected = props.selected || false;

        // const parsedJson:any = JSON.parse( props )

        Object.keys( props ).forEach(
          ( key: string ) => {
            (this as any)[ key ] = props[ key ]
          }
        )

        this.field = props.field;
        this.formPage = this.field.formPage;
        this.form = props.form;

        this.subFormPages = (props.subFormPages || []).map(item => {
            item.form = this.form;
            return new FormPage(item)
        });
    }

    get bindValue(): any {
        return this.field.bindValue ? (this as IOption)[this.field.bindValue] : this
    }
    get bindLabel(): any {
        return this.field.bindLabel ? (this as IOption)[this.field.bindLabel] : this
    }

    onTap() {
        // console.log("Bubble tap...");

        if (this.subFormPages && this.subFormPages.length > 0) {
            if (this.selected) {
                this.form.addPages(this.subFormPages, { clearControls: true, parent: this.formPage.key });
            } else {
                this.form.removePages(this.subFormPages, { clearControls: true })
            }
        }
    }

    addSubPages(): void {
        if (this.selected && this.subFormPages) {
            this.form.addPages(this.subFormPages, { clearControls: true, parent: this.formPage.key });
        }
    }

    removeSubPages(): void {
        if (!this.selected && this.subFormPages) {
            this.form.removePages(this.subFormPages, {clearControls: true});
        }
    }
}