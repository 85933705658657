import React, { forwardRef } from "react";
import { SingleSelect } from "components/singleSelect";
import { DatePicker } from "components/datePicker";
import { TextField } from "components/textField";
import { Button } from "components/button";
import { CouponInformation } from "components/couponInformation";
import { FormTitle } from "components/form/formTitle";
import { ZipCode } from "components/zipCode";

const InputControlSelector = ({ field, formPage, onClick, ...props }: any) => {
  const selectControl = (fieldType: any) => {
    let view: any;
    switch (fieldType) {
      case 'singleSelect':
        //@ts-ignore
        view = <SingleSelect
          field={field}
          options={field.options}
          formControl={field.formControl}
          bindLabel={field.bindLabel}
          bindValue={field.bindValue}
        />
        break;
      case 'datePicker':
        view = <DatePicker formControl={field.formControl} />
        break;
        case 'zipcode':
        view = <ZipCode 
            formControl={field.formControl} 
             onClick={onClick}
          />
        break;
      case 'textField':
        view = <TextField
          label={field.label}
          formControl={field.formControl}
          keyboardType={field.keyboardType}
          mask={field.mask}
        />
        break;
      case 'button':
        view = <Button
          text={field.label}
          formPage={formPage}
          formControl={field.formControl}
          onClick={onClick}
          style={{marginTop:'20em', marginLeft:"4em"}}
        />
        break;
      case 'couponInfo':
        //debugger;
        if (field.isVisible) {
          //  debugger;
          view = <CouponInformation
            promoCode={'MOVE1005'}
            discountAmount={'50'}
          />
        } else {
          view = null;
        }

        break;
      case 'title':
          view = <FormTitle
            backgroundColor={"white"}
            title={field.value.title}
            subTitle={field.value.subTitle}
          />

        break;
    }
    return view;
  }

  return (
    selectControl(field['fieldType'])
  )
}

export default InputControlSelector;
