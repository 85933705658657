import React from "react";
import ReactDOM from 'react-dom'
import './MoveWith.scss'
import { BrandAdverbs } from './BrandAdverbs';
//@ts-ignore
import Slider from "react-slick";
import { Header } from '../../../header';
import { useSpring, a, config } from 'react-spring'
import { Button } from "components/button";
import Animation from './Animation';
//import {BrandAdverbs} from './BrandAdverbs';
import { navigate } from '@reach/router';
import { covidimg, bluehero } from "assets/images";
import { Popup } from "components/Popup"
import { Covid19Policy } from "components/covid19Policy";

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

export interface MoveWithCardProps {
  height?: any;
}

export interface MoveWithCardState {
  isAnimated?: boolean;
  open: boolean;
}

class MoveWithCard extends React.Component<MoveWithCardProps, MoveWithCardState> {
  slider: any;
  constructor(props: MoveWithCardProps) {
    super(props);
    this.state = {
      isAnimated: true,
      open: false,
    };
  }

  componentDidMount() {
    let element: any = document.querySelector('main');
    let cardopen: any = element.classList.contains('frozen');
    let metaTag: any = document.querySelector('meta[name="theme-color"]');

    if (cardopen === false) {
      let themeColor: any = metaTag.content = "#F3EFE4";
      // console.log("the theme color is tan");
    }

  }
  openPopup = () => {
    this.setState({ open: true });
  }
  getStarted() {
    navigate("/moveWithForm")
  }
  onLeftIconPress() {
    navigate('/move');
  }

  stopAnimation() {
    this.setState({ isAnimated: false });
  }



  render() {

    return (
      <a.div className="" style={{ backgroundColor: '#ffffff', borderRadius: 16, height: this.props.height }} onClick={this.getStarted}>
        {/* <Header
                progress={undefined}
                leftIcon={'tag'}
                leftText={'pricing'}
                rightIcon={'phone'}
                rightText={'Call Us'}
                onLeftIconPress={() => this.onLeftIconPress()}
            /> */}
        <div className="main-content">
          <div className="move-heading">
            <h1>Trust</h1>
                <h2> over a million moves </h2>
                <h2> in 91 years </h2>
          </div>
          <div className="main-img">
            <img src={bluehero} />
          </div>
          <div className="main-btn">
            <Button
              text={"Get Pricing"}
              onClick={this.getStarted} />
          </div>
        </div>
        {/* 
              {this.state.isAnimated ? (
                        <Animation brandAdverbs={BrandAdverbs()} stop={() => this.stopAnimation()}/>
                    ) : (
                      <div className="adverb-inner">
                        <div className="adverb static">
                          {BrandAdverbs()[0].img}
                        </div>
                      </div>
                    )} */}





      </a.div>
    );
  }
}

export default MoveWithCard;
