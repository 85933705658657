import React, { forwardRef, useRef, useState, useCallback, useEffect } from 'react'
import { useStore } from '../../index'
import './MoveWithForm.scss'
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from 'react-reactive-form';
import { IForm, FormModel, IField, IOption, Option, IFormPage } from 'shared/model/form';
import { Form } from 'components/form';
import moment from 'moment';
import { getEstimatorDetails } from 'core/services/movewithService';
import { availableMoveSizeDetailsApartment } from './data/availableMoveSizeDetailsApartment';
import { availableMoveSizeDetails } from 'screens/moveWithForm/data';
import { createLeadQuery } from "core/services/LeadService"
import { client } from "index";



export interface MoveWithFormProps {
    form: any;
    couponDates: any[]
    // formGroup: FormGroup;
    // formModel: IForm;
    width: number;
    path?: string;
    onBack?(): void;
    onLogoTap?(): void;
}

const MoveWithForm = ({ form, couponDates, width, onBack }: MoveWithFormProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const ref = useRef(null) // Our main dom Ref
    const navigate = useStore((state: any) => state.navigate)
    const endNav = useStore((state: any) => state.endNav)

    const [formGroup, setFormGroup] = useState(getMoveWithFormGroup());
    const [formModel, setFormModel] = useState(new FormModel(form.formJSON, formGroup));
    const [sliderRef, setSliderRef] = useState(null);

    const onDone = (formValue: any) => {
       // form.apiAction(formValue);
       // debugger;
        //  sendSMS(formValue.FirstName, formValue.Telephone);
        console.log(formValue);
            localStorage.setItem("done", "1")
            
        if (localStorage.getItem("coupon")==="y") {
            form.smsAction(formValue.FirstName, formValue.Telephone)
            localStorage.removeItem("coupon")
        }
       // let respone_amounts =  getAmounts();
       // console.log(respone_amounts)
        
            addLead();

          
/* if(localStorage.getItem("office")==="NVL")
navigate("nvlthanks")
else
        navigate(form.routeOnDone, ref.current); */
    }

    const addLead = () => {
        localStorage.setItem("leadDetails", "")
        createLead()
            .then(response => {
                
              console.log(response.data.saveAMSLead[0])
                setTimeout(() => {
                    localStorage.setItem("office", response.data.saveAMSLead[0].office)
                  }, 5000);
                  if(response.data.saveAMSLead[0].office==="NVL"){
                      localStorage.setItem("office", "NVL")
                    navigate("nvlthanks")
                  }
                  else{
                    localStorage.setItem("office", "AMS")
                    navigate("thanks")
                  }
                
                
            });
          }

    const createLead= async () =>{
        var object = JSON.parse(localStorage.getItem("movewith") || '{}');
        var MoveType = object.MoveType
        // console.log(object);
        var MoveSize = getMoveSizeFromTypeAndBedrooms(object.MoveSize, parseInt(object.MoveSizeDetail.toString())); // dbvalue;
        var MoveDate= null
        var Telephone = object.Telephone;
    Telephone = Telephone.replace('(', '').replace(')', '').replace(' ', '-').replace(' ', '-');
    var movingDateNotSure= null;
if(localStorage.getItem("notsure")==="1")
{
    MoveDate = moment().add(15,"days").format("MM/DD/YYYY")
    movingDateNotSure="1"
}
else{
    MoveDate = moment(object.MoveDate).format("MM/DD/YYYY")
    movingDateNotSure="0"
}

if(MoveType!=="Local")
{
        return client.query({
            query: createLeadQuery, variables: {
                nvlDataInput: {
                    emailAddress:"",
                    fromZip:localStorage.getItem("origin"),
                    iP:localStorage.getItem("ip"),
                    toZip:localStorage.getItem("destination"),
                    moveDate:MoveDate,
                    moveSize:MoveSize,
                    moveType:object.MoveType,
                    name:object.FirstName,
                    phoneNumber:Telephone,
                    source:localStorage.getItem("Src"),
                    uRL:localStorage.getItem("url"),
                    movingDateNotSure:movingDateNotSure
                }
            }
        });
    }
    else{
        return client.query({
            query: createLeadQuery, variables: {
                nvlDataInput: {
                    emailAddress:"",
                    fromZip:localStorage.getItem("origin"),
                    iP:localStorage.getItem("ip"),
                    moveDate:MoveDate,
                    moveSize:MoveSize,
                    moveType:object.MoveType,
                    name:object.FirstName,
                    phoneNumber:Telephone,
                    source:localStorage.getItem("Src"),
                    uRL:localStorage.getItem("url"),
                    movingDateNotSure:movingDateNotSure
                }
            }
        });
    }

    }
    
    

    const getMoveSizeFromTypeAndBedrooms = (moveType: any, bedrooms: number) => {
    	//Accepts a string and an int.
// console.log(moveType, bedrooms)
		switch(moveType) {
			case "House":
			  // code block
				if(bedrooms >= 4){
					return "House 4 Bedroom or more";
				} else if(isNaN(bedrooms)){return "House 2 Bedroom"
				} else {return "House " + bedrooms + " Bedroom"}
			case "Apartment":
			// code block
				if(bedrooms >= 2){
					return "Apt 2 Bedroom or more";
				} else if(isNaN(bedrooms)) {return "Apt 1 Bedroom"
				} else {return "Apt " + bedrooms + " Bedroom"}
			case "Condo":
				return "Condo";
			default:
				return "Other";
		}

}
    const [submitted, setSubmitted] = useState(false);
    const [coupon, setCoupon] = useState(false);

    const isCoupon = (selectedDt: string) => {
        if (couponDates.length > 0 && selectedDt) {
            var selectedDate: any = moment(selectedDt).format('MM-DD-YYYY');
            return couponDates.indexOf(selectedDate) > -1;
        }
        // no date selected (I'M NOT SURE) case 
        else if (!selectedDt) {
            let twoWeekDate = moment().add(15,"days").format('MM-DD-YYYY');
           // let couponDateInNextTwoWeeks = couponDates.find(couponDate => new Date(couponDate) < twoWeekDate);
           return couponDates.indexOf(twoWeekDate) > -1;
        } else {
            return false;
        }
    };

    const manageCouponPage = (moveDate: any) => {
        if (isCoupon(moveDate)) {
            //@ts-ignore
            formModel.formPages[formModel.currentFormPageIndex + 1].fields[0].isVisible = true;
            formModel.formPages[formModel.currentFormPageIndex + 1].title = '';
            setFormModel(new FormModel(formModel, formGroup));
            localStorage.setItem("won","y")
            localStorage.setItem("coupon","y")
            
        } else {
            //@ts-ignore
            formModel.formPages[formModel.currentFormPageIndex + 1].fields[0].isVisible = false;
            formModel.formPages[formModel.currentFormPageIndex + 1].title = "Let's Discuss Your Quote!";
            setFormModel(new FormModel(formModel, formGroup));
            localStorage.setItem("won","n")
            localStorage.setItem("coupon","n")
        }
    }

    const onValueChange = useCallback(value => {
       // console.dir(value);
       // const ZipcodeRegex =/(^\d{5}$)|(^\d{5}-\d{4}$)/
       
        
        if (formModel.currentFormPage.key === 'date' && value.MoveDate) {
          //  manageCouponPage(value.MoveDate);
          
        }
        
      
        let currentPage = formModel.formPages[formModel.currentFormPageIndex];
        if (currentPage && currentPage.key === "type") {

            let nextPage = formModel.formPages[formModel.currentFormPageIndex + 1];
            
            if (value.MoveSize === "Apartment" && !!nextPage) {
                (nextPage.fields[0] as IField).options = getOptions(availableMoveSizeDetailsApartment, nextPage)
            }
            if (value.MoveSize === "House" && !!nextPage) {
                (nextPage.fields[0] as IField).options = getOptions(availableMoveSizeDetails, nextPage)
            }
        }
      //  value.Zipcode=localStorage.getItem("zip")
        localStorage.setItem("movewith", JSON.stringify(value));

        if (value.done) {
            formGroup.markAsSubmitted();
            formGroup.markAsTouched();
            setSubmitted(true);
           // formGroup.valid=true;
        }
        // console.log("INNNNN->", formGroup.valid)
      if (value.done && formGroup.valid) {
         //   if (value.done) {
            onDone(value);
        }
    }, [currentIndex, couponDates, formModel])

    const getOptions = (optionsData: any[], nextPage: IFormPage) => {
        return optionsData.map((item: any) => {
            item.field = (nextPage.fields[0] as IField);
            item.form = formModel;
            let option: IOption =  new Option(item);
            return option;
        })
    }

    const onBackNavigation = () => {
        if (onBack) {
            onBack();
        }
    }

    const onPreviousPage = () => {
    }

    const onFooterTap = () => {
        let formControl: AbstractControl = formGroup.get('MoveDate');
        if (formControl) {
            formControl.setValue('', { emitEvent: false });
        }
  //      manageCouponPage('');
    }

    const onLogoTap = () => {
        if (onBack) {
            onBack();
        }
    }

    const onMount = (ref: any) => {
        setSliderRef(ref);
    }
    const handleSubmit = (e: any) => {

        if (!formGroup.valid) {
            let formControl: AbstractControl = formGroup.get('FirstName');
            if (formControl) {
                formControl.setValue('', { emitEvent: false });
            }
            let formControl1: AbstractControl = formGroup.get('Telephone');
            if (formControl1) {
                formControl1.setValue('', { emitEvent: false });
            }
            e.preventDefault();
        }
    }

    useEffect(() => {
        if (!formModel.isLastPage && sliderRef) {
            setTimeout(() => {
                //@ts-ignore
                // sliderRef.slickNext();
            }, 500)
        }
    }, [formModel, sliderRef]);

    return (
        <form onSubmit={e => handleSubmit(e)}>

            <Form
                // ref={ref}
                form={formModel}
                formGroup={formGroup}
                index={formModel.currentFormPageIndex}
                width={width}
                submitted={submitted}
                onValueChange={onValueChange}
                // onIndexChange=
                // {(index: number) => setCurrentIndex(index)
                // }
                onBack={onBackNavigation}
                onPreviousPage={onPreviousPage}
                onFooterTap={onFooterTap}
                onLogoTap={onLogoTap}
                onMount={onMount}
            />
        </form>
    )
};

export default MoveWithForm;

function phoneNumberValidator(formControl: FormControl) {
    // validate USA phone number
    if (!/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/.exec(formControl.value)) {
        return { phone: true }
    } else {
        return null;
    }
}
function zipCodeValidator(formControl: FormControl) {
    // console.log(formControl,'tel operator')
    // validate USA phone number
    if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.exec(formControl.value)) {
        return { Zipcode: true }
        return { zipCode: true }
    } else {
        return null;
    }
}
function getMoveWithFormGroup(lead: any = {}) {

    const { MoveType, MoveSize, MoveDate, FirstName, Email, Telephone } = lead;

    return FormBuilder.group(
        {
            // MoveType: [MoveType, Validators.required],
            // MoveSize: [MoveSize, Validators.required],
            // MoveDate: [MoveDate, Validators.required],
            // FirstName: [FirstName, Validators.required],
            // Email: [Email, Validators.email],
           // Zipcode:['',zipCodeValidator],
            Telephone: ['', phoneNumberValidator],
            // done: ['']
        }
    );
}

function getCouponPage() {
    return {
        "id": "",
        "title": "COUPON PAGE",
        "key": "confirm",
        "shortTitle": "",
        "fields": [
            // {
            //     // "label": "Name",
            //     "fieldType": "couponInfo",
            //     // "required": true,
            //     // "formControlName": "FirstName",
            //     // "value": lead.FirstName,
            //     "order": 1,
            //     isVisible: () => true
            // },
            {
                "label": "First Name",
                "fieldType": "textField",
                "required": true,
                "formControlName": "FirstName",
                // "value": lead.FirstName,
                "order": 1
            },

            {
                "label": "Phone",
                "fieldType": "textField",
                "formControlName": "Telephone",
                "keyboardType": "tel",
                "validators": {
                    // Regex for USA phone pattern validation
                    "custom": /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/
                },
                "mask": ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                // "value": lead.Telephone,
                "order": 3
            },
            {
                "label": "CONTINUE",
                "fieldType": "button",
                "formControlName": "done",
                // "keyboardType": "tel",
                // "mask": ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                // "value": "",
                "order": 4
            },
        ]
    }
}

function getConfirmPage() {
    return {
        "id": "",
        "title": "SIMPLE PAGE",
        "key": "confirm",
        "shortTitle": "",
        "fields": [
            {
                "label": "First Name",
                "fieldType": "textField",
                "required": true,
                "formControlName": "FirstName",
                // "value": lead.FirstName,
                "order": 1
            },

            {
                "label": "Phone",
                "fieldType": "textField",
                "formControlName": "Telephone",
                "keyboardType": "tel",
                "validators": {
                    // Regex for USA phone pattern validation
                    "custom": /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/
                },
                "mask": ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                // "value": lead.Telephone,
                "order": 3
            },
            {
                "label": "CONTINUE",
                "fieldType": "button",
                "formControlName": "done",
                // "keyboardType": "tel",
                // "mask": ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                // "value": "",
                "order": 4
            },
        ]
    }
}
