import { gql } from 'apollo-boost';
import { fetchGraphQL } from './graphqlService'

export const createLeadQuery = gql`
query savenvlLead($nvlDataInput: NvlDataInputType!) {
  saveAMSLead(nvlDataInput: $nvlDataInput) {
      name
      source
      fromZip
      toZip
      phoneNumber
      iP
      office
      moveType
      moveSize
    }
  }
`;