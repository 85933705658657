import React, { Component, useState, useEffect } from 'react'
import { FormControl } from 'react-reactive-form';
import './Thankyou.scss';
import { CountdownTimer } from '../../components/CountdownTimer'
import { MoveCounter } from '../../components/Thankyou/MoveCounter'
import { FormTitle } from '../../components/form/formTitle';
import { ConfirmationMessage } from '../../components/Thankyou/ConfirmationMessage';
import { Checklist } from '../../components/Thankyou/Checklist';
import { getEstimatorDetails } from 'core/services/movewithService'
import { Header } from 'components/header';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useStore } from '../../index';
import { ThankyouCard } from 'components/thankyouCard';
import { logo, avatar } from "assets/images";
import { navigate } from '@reach/router';


declare global {
    interface Window { dataLayer: any; }
    interface Window { dataLayer: any; }
}
window.dataLayer = window.dataLayer || {};
window.fbq = window.fbq || {};

export interface ThankyouProps {
    formControl?: FormControl;
    path?: string;
    progress?: number;
}

export interface ThankyouState {
    header: any;
    bullets: any;
    cid: any;
    firstName: any;
    employeeImg: any;

}

const Thankyou = ({ formControl, path, progress }: ThankyouProps) => {
    const endNav = useStore((state: any) => state.endNav)

    const [firstName] = useState(localStorage.getItem("FirstName") || undefined);
    const [employeeImg] = useState(localStorage.getItem("EmployeeImg"));

    const moveData = JSON.parse(localStorage.getItem("movewith") || "{}");

    const getNavigatedPageUrl = (moveWithObj: any) => {
        let pageUrl = '/';
        if (moveWithObj.MoveSize === "") {
            pageUrl = "/move?question=type";
        } else if (moveWithObj.MoveSizeDetail === "") {
            pageUrl = '/move?question=size';
        } else if (moveWithObj.MoveType === "") {
            pageUrl = "/move?question=move";
        } else if (moveWithObj.MoveDate === "") {
            pageUrl = "/move?question=date";
        } else if (moveWithObj.FirstName === "" || moveWithObj.Telephone === "") {
            pageUrl = "/move?question=confirm";
        }
        return pageUrl;
    }

    useEffect(() => {
        clearAllBodyScrollLocks();
        window.scrollTo(0, 0);
        const done: any = moveData.done;
        if (!done) {
            const url: any = getNavigatedPageUrl(moveData);
            navigate(url);
        }
        //   getEstimatorDetails(9);
           window.dataLayer.push({
             event: 'pageview',
             page: { path: "/thanks" }
         }) 
     
    }, []);

    const onLeftIconPress = () => {
        navigate("/");
        setTimeout(() => {
            endNav()
        }, 400);
    }

    return (
        
        <div className="thankyou">

            <Header
                // progress={100}
                // leftIcon={'arrow-left'}
                // leftText={'Home'}
                rightIcon={'phone'}
                rightText={'Call Us'}
                
              //  onLeftIconPress={onLeftIconPress}
            
            />

            <div className="thankyou-title">
                <h1>The best move, period!</h1>
                <p>We recommend our trusted partner, All My Sons Moving & Storage, for your local move.</p>
            </div>
            {/* <ConfirmationMessage name={firstName} variant="success" /> */}

            {/* <FormTitle title={"Calling you now"} subTitle={""}></FormTitle> */}

            {/* <CountdownTimer /> */}

            {/* <MoveCounter /> */}
            <div className="inner-wrapper">
                <ThankyouCard
                logoImg={logo}              
                moverInfo={localStorage.getItem("FirstName") +" is calling you now!"}
                EmployeeImg={localStorage.getItem("EmployeeImg")}
                smallText={"We love what we do and you’ll love moving with us."}
                topStarRating={true}
                />
                <div className="copyright-text">
                    <p>Powered by Movingful</p>
                </div>

            </div>

        </div>
    )

}

export default Thankyou;
