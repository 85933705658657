
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { lighten, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import './ProgressBar.scss'

const ColorLinearProgress: any = withStyles({
    root: {
        height: 6,
    },
    colorPrimary: {
      backgroundColor: '#ECECEC',
    },
    barColorPrimary: {
      backgroundColor: '#004d94',
    },
    bar: {
        borderRadius: 20
      },
      //@ts-ignore
  })(LinearProgress);

const useStyles: any = makeStyles((theme: Theme) =>
//@ts-ignore
  createStyles({
    root: {
      flexGrow: 1,
    },
    margin: {
      margin: theme.spacing(0),
    },

  }),
);

export interface ProgressbarProps {
    progress: number;
}

const ProgressBar = ({ progress }: ProgressbarProps) => {
    //@ts-ignore
    const classes = useStyles();
    const [currentThemeColor, setCurrentThemeColor] = useState('#00945D');

    useEffect(() => {
      let metaTag: any  = document.querySelector('meta[name="theme-color"]');
      let themeColor: any = metaTag.content = currentThemeColor;
    //  console.log("the theme color is green ", themeColor);
  }, [currentThemeColor]);

    return (
        <div>
            <ColorLinearProgress className={classes.margin} variant="determinate" value={progress} />
        </div>

    );
}

export default ProgressBar;
