import React, { forwardRef, useRef, useState, useEffect } from 'react'
import ReactDOM, { render } from 'react-dom'
//@ts-ignore
// import { useStore } from '../../index'
// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
//@ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Form.scss'
import { FormTitle } from 'components/form/formTitle';
import { IForm, IFormPage, IField, IOption } from 'shared/model/form';
import { FormGroup } from 'react-reactive-form';
import { FormSection } from 'components/form/formSection';
import { Header } from 'components/header';
import { Select } from 'components/select';
import { navigate } from '@reach/router';
import { CouponInformation } from "components/couponInformation"
//@ts-ignore
import SimpleLineIcon from 'react-simple-line-icons';
//@ts-ignore
import moment from 'moment';
import { Button } from 'components/button';
var progressVar = 0
const couponDates = JSON.parse(localStorage.getItem("CouponDates") || '{}');
/*const couponDates =[
    "28-11-2019",
    "29-11-2019",
    "10-12-2019",
    "11-12-2019",
    "12-12-2019",
    "13-12-2019",
    "21-12-2019",
    "22-12-2019",
    "23-12-2019"
] */

export interface FormProps {
    form: IForm;
    formGroup: FormGroup;
    index: number;
    disableSwipe?: boolean;
    hideNextButton?: boolean;
    hideProgressTabs?: boolean;
    backNavigation?: boolean;
    headerHeight?: string;
    width: number;
    ref?: any;
    submitted?: boolean;
    onSave?(): void;
    onDiscard?(): void;
    onValueChange?(value: any): void;
    onIndexChange?(index: number): void;
    onBack?(): void;
    onPreviousPage?(): void;
    onFooterTap?(): void;
    onLogoTap?(): void;
    onMount?(sliderRef: any): void;
}

export interface FormState {
    progress: any;
    currentIndex: number;
    formPages: IFormPage[];
    footer: boolean;
    coupon: boolean;
    initialSlide: number;
    swiperSetting: any;
    footerbtn?: boolean;
}

declare global {
    interface Window { dataLayer: any; }
    interface Window { fbq: any; }
}
window.dataLayer = window.dataLayer || {};
window.fbq = window.fbq || {};


class Form extends React.Component<FormProps, FormState> {
    sliderRef: any;

    constructor(props: FormProps) {
        super(props);
        let index = 0;
        this.props.form.currentFormPageIndex = index;
        navigate(`${'move'}?question=${this.props.form.currentFormPage.key}`);

        this.state = {
            progress: 10, //(((this.props.form.currentFormPageIndex + 1) / this.props.form.formPages.length) * 100),
            currentIndex: this.props.index,
            formPages: this.props.form.visibleFormPages,
            footer: window.location.search === '?question=date' || false,
            coupon: false,
            initialSlide: index,
            swiperSetting: this.swiperSetting(index),
            footerbtn: false,

        };
        this.onFormChanges = this.onFormChanges.bind(this);
        this.beforeChange = this.beforeChange.bind(this);
    }

    // const Form = ({ form, formGroup, index, width, onValueChange, onIndexChange, onBack, submitted }: FormProps, sliderRef: any) => {
    // const sliderRef = useRef(null) // Our main dom Ref
    // const navigate = useStore((state: any) => state.navigate)
    // const endNav = useStore((state: any) => state.endNav)

    // const [formPages, setFormPages] = useState(form.visibleFormPages);
    // const [currentFormPage, setCurrentFormPage] = useState(form.currentFormPage);
    // const [currentIndex, setCurrentIndex] = useState(index);
    // const [progress, setProgress] = useState((((form.currentFormPageIndex + 1) / form.formPages.length) * 100));

    componentDidMount() {
        this.props.formGroup.valueChanges.subscribe(this.onFormChanges);
       // console.dir(window.location);
        // if (window.location.search === '?question=type') {
        //     debugger;
        //     let index = 1;
        //     this.props.form.currentFormPageIndex = index;
        //     this.setState({ initialSlide: index, swiperSetting: this.swiperSetting(index) });
        //     navigate(`${'move'}?question=${this.props.form.currentFormPage.key}`);
        // } else {
        //     navigate(`${'move'}?question=${this.props.form.currentFormPage.key}`);
        // }
        var key = "/" + this.props.form.currentFormPage.key
        // console.log(this.props.form.currentFormPage.key);
        window.dataLayer.push({
            event: 'pageview',
            page: { path: key }
        })
     /*    window.fbq.push({
            event: 'PageView',
            page: { path: key }
        }) */
        if (this.props.onMount) {
            this.props.onMount(this.sliderRef);
        }
    }

    componentDidUpdate(prevProps: FormProps) {
        if (this.props.formGroup !== prevProps.formGroup) {
            // debugger;
            this.props.formGroup.valueChanges.subscribe(this.onFormChanges);
        }
    }

    componentWillUnmount() {
        // debugger;
        this.props.formGroup.valueChanges.unsubscribe(this.onFormChanges);
        window.onpopstate = null;
        // if (this.props.onBack) {
        //     this.props.onBack();
        // }
    }

    goToNextPage() {
        //  alert("inn")
        progressVar = this.state.progress + 10
        this.setState({ progress: progressVar })
        if (this.sliderRef && !this.props.form.isLastPage) {
            setTimeout(() => {
                this.sliderRef.slickNext();
            }, 10)
        }

    }

    onFooterTap() {
        localStorage.setItem("notsure", "1")
        const moveData = JSON.parse(localStorage.getItem("movewith") || "{}");
        moveData.MoveDate = moment().add(15, "days").format("MM/DD/YYYY");
        localStorage.setItem("movewith", JSON.stringify(moveData));
        this.goToNextPage();
        if (this.props.onFooterTap) {
            this.props.onFooterTap();
        }
    }

    goToPreviousPage() {
        progressVar = this.state.progress - 10
        this.setState({ progress: progressVar })
        if (this.sliderRef) {
            this.sliderRef.slickPrev();
        }
        if (this.props.onPreviousPage) {
            this.props.onPreviousPage();
        }
    }

    onFormChanges(value: any) {

        this.setState({ formPages: [...this.props.form.getVisiblFormPages(this.props.form.formPages)] })


        // Automatically move to next page on single bubble selection.
        if (this.props.form.currentFormPage.valid && this.props.form.currentFormPage.goNextOnValid) {
            if (!this.props.form.isLastPage) {
                this.goToNextPage();
            }
            let field: IField | IField[] = this.props.form.currentFormPage.fields[0] as IField;
            //  console.clear();
            var key = "/" + this.props.form.currentFormPage.key;
           // console.dir(key)
            // console.log(value);

            // console.log(this.props.form.currentFormPage)

            if (typeof field === 'object' && field.fieldType === 'singleSelect') {
                // setTimeout(() => {
                // }, 100)
            }
        }

        if (this.props.onValueChange) {
            this.props.onValueChange(value);
        }
    }

    onFormPageButtonClick() {
        if (this.props.form.currentFormPage.valid || this.props.form.currentFormPage.goNextOnValid) {
            if (!this.props.form.isLastPage) {
                this.goToNextPage();
            }
        }
    }

    beforeChange(oldIndex: number, newIndex: number) {
        //  console.log("aaaaaaaaaaaaaaaaa")

        //console.log(this.props.form.formPages.length+"------------->"+newIndex + 1)
        //      let progress: number = ((newIndex + 1) / this.props.form.formPages.length) * 100;
        //    this.setState({ progress: progress });

        // Scroll view to top on web
        var moveData = JSON.parse(localStorage.getItem("movewith") || "{}");
        var moveType = moveData.MoveType;
        if(moveType==="LongDistance" || moveType==="ShortHaul"){
            localStorage.setItem("isNVL","1")
        }
        else
        {
         localStorage.setItem("isNVL","0")
        }
        window.scrollTo(0, 0);

        let nextFormPage = this.props.form.formPages[newIndex];
        if (nextFormPage) {

            navigate(`${'move'}?question=${nextFormPage.key}`);
            window.onpopstate = (e: any) => {
                // console.log("Browser back button presses");
                if (this.props.form.currentFormPageIndex !== 0) {
                    this.goToPreviousPage();
                } else {
                    if (this.props.onBack) {
                        this.props.onBack();
                    }
                }
            }

            this.setState({ footer: !!nextFormPage.footer })
           this.setState({ footerbtn: !!nextFormPage.footerbtn })
        }

        this.props.form.currentFormPageIndex = newIndex;
        var key = "/" + this.props.form.currentFormPage.key;
        window.dataLayer.push({
            event: 'pageview',
            page: { path: key }
        })
     /*    window.fbq.push({
            event: 'PageView',
            page: { path: key }
        })  */
        this.setState({ currentIndex: newIndex });
    }

    swiperSetting(initialSlideIndex: number): any {
        return {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            arrows: false,
            lazyLoad: 'ondemand',
            initialSlide: initialSlideIndex,
            adaptiveHeight: true,
            // fade: true,
            // useCNumberLiteralSS: true,
            // useTransform: true,
            // cssEase: `cubic-bezier(0.39, 0.575, 0.565, 1)`,
            speed: 500,
            // draggable: true,
            // variableWidth: true,
            swipe: false,
            beforeChange: (oldIndex: number, newIndex: number) => this.beforeChange(oldIndex, newIndex),
            afterChange: (index: any) => {
                this.props.form.currentFormPageIndex = index;
                //  let progress: number = ((index + 1) / this.props.form.formPages.length) * 100;
                // this.setState({ progress: progress });
                this.setState({ currentIndex: index });


                if (this.props.onIndexChange) {
                    this.props.onIndexChange(index);
                }
            }
        };
    }


    onBackPress() {
        localStorage.setItem("notsure", "0");
      //  localStorage.removeItem("origin1")
        //localStorage.removeItem("origin")
        //localStorage.removeItem("destination")

        const moveWith = JSON.parse(localStorage.getItem("movewith") || "{}");
        // moveWith.MoveDate = "";
        //localStorage.setItem("movewith", JSON.stringify(moveWith));
        localStorage.setItem("isText", "0")
        localStorage.setItem("isAffirmText","0")
        if (this.props.form.currentFormPageIndex === 0) {
            if (this.props.onBack) {
                this.props.onBack();
            }
        } else {
            this.goToPreviousPage();
        }
    }

    onLogoPress() {
        if (this.props.onLogoTap) {
            this.props.onLogoTap();
        }
    }

    getFullPages(form: IForm): IFormPage[] {
        let formPages: IFormPage[] = [form.formPages[0]];
        let localOption: IOption | undefined = ((form.formPages[0].fields[0] as IField).options || []).find(item => item.label === "Local");
        if (localOption) {
            //@ts-ignore
            formPages.push(...localOption.subFormPages)
            //@ts-ignore
            let apartmentOption: IOption | undefined = (localOption.subFormPages[0].fields[0] as IField).options.find(item => item.value === "Apartment");
            if (apartmentOption) {
                //@ts-ignore
                formPages.push(...apartmentOption.subFormPages)
                //@ts-ignore
                let onebedroomOption: IOption | undefined = (apartmentOption.subFormPages[0].fields[0] as IField).options.find(item => item.value === "1 Bedroom Apartment");
                if (onebedroomOption) {
                    //@ts-ignore
                    formPages.push(...onebedroomOption.subFormPages)
                }
            }
        }
        return formPages;
    }

    // endNav();
    render() {
        return (
            <div className="form-section" style={{ width: this.props.width }}>
                <div className="header-section">
                    <Header backIcon={true} onLogoPress={() => this.onLogoPress()} onBackPress={() => this.onBackPress()} progress={this.state.progress} leftIcon={'arrow-left'} rightIcon={'phone'} rightText={"Call Us"} />

                </div>
                <div className="section-padding">
                    <Slider
                        ref={(ref: any) => this.sliderRef = ref}
                        {...this.state.swiperSetting}
                        initialSlide={this.state.initialSlide}
                    >
                        {this.props.form.formPages.map((formPage: IFormPage, index: number) => {
                            return (
                                <div key={index}>
                                    {formPage.title &&
                                        <FormTitle title={formPage.title} subTitle={formPage.subTitle}></FormTitle>
                                    }

                                    <FormSection
                                        form={this.props.form}
                                        formPage={formPage}
                                        onClick={() => this.onFormPageButtonClick()}
                                    >
                                    </FormSection>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
                {this.state.footer &&
                    <div className="main-footer">
                        <Select
                            label={"I'M NOT SURE"}
                            onBubbleSelect={() => this.onFooterTap()} />
                        <div className="footer-icon" onClick={() => this.onFooterTap()}>
                            <SimpleLineIcon name="arrow-right" />
                        </div>
                    </div>
                }
                {this.state.footerbtn &&
                    <div className="main-footerbtn">
                        <Button
                            text={"Continue"}
                            formPage={this.props.form.currentFormPage}
                            onClick={() => this.goToNextPage()}
                        />
                    </div>
                }
            </div>
        )
    }
};

export default Form;
