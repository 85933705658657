import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Button as MaterialButton, CircularProgress, Typography } from '@material-ui/core';
import './Button.scss'
//@ts-ignore
import SimpleLineIcon from 'react-simple-line-icons';

const ColorButton: any = getButton();

export default function Button({
  text,
  width,
  height,
  fontSize,
  backgroundColor,
  color,
  fontWeight,
  textTransform,
  marginTop,
  paddingTop,
  formControl,
  formPage,
  formGroup,
  iconName,
  loading,
  onClick,
  border,
  style,
  disabled,
}: any) {

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (formControl) {
      formControl.setValue(true);
    }
  }
  const value = (formPage && !formPage.valid) || (formGroup && !formGroup.valid) || false || localStorage.getItem("done")==="1";
//   console.log('values!!!!!!!!!!', value);
  // console.log('disabled!!!!!!!!', disabled);
  const finalValue = disabled !== undefined ? disabled : value;
  return (
    <div className="comfort-btn">
      <ColorButton
        variant='contained'
        disabled={finalValue}
        style={{ width: width, height: height, fontSize: fontSize, backgroundColor: backgroundColor, fontWeight: fontWeight, textTransform: textTransform, marginTop: marginTop, paddingTop: paddingTop, border: border, color:color, ...style }}
        onClick={() => handleClick()}
        // backgroundColor={backgroundColor}
      >
        {loading ?
          <CircularProgress className="pre-loader" size={'1.8em'} style={{ color: 'white' }} /> :
          <Typography style={{ color: color,fontSize: fontSize }}>{text}
          </Typography>
        }
        {iconName &&
          <SimpleLineIcon name={iconName} style={{ color: color }} />
        }
      </ColorButton>
    </div>
  );
}

function getButton(): any {
  //@ts-ignore
  return withStyles(theme => ({
    root: {
      color: '#ffffff',
      backgroundColor: '#004d94',
      borderColor: '#004d94;',
      // boxShadow: 'none',
      boxShadow: '-1px 2px 3px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.05)',
      textAlign: 'center',
      borderRadius: 100,
      // minHeight: 48,
      // width: 'calc(100% - 30px)',
      // maxWidth: '540px',
      width: "90%",
      borderWidth: 0,
      // fontSize: "2rem",
      fontFamily: "'Heebo', sans-serif",
      textTransform: "uppercase",
  
      '&:hover': {
        backgroundColor: '#004d94',
        borderColor: 'none',
        cursor: 'pointer',
        color: '#ffffff',
        borderWidth: 0,
        textTransform: "uppercase",
        boxShadow: 'none',
      },
      '&:focus': {
        outline: 0,
        boxShadow: 'none',
      },
  
  
      rippleVisible: {
        opacity: 0.8
      },
    },
    "@keyframes mui-ripple-enter": {
      "0%": {
        transform: "scale(0)",
        opacity: 0.1
      },
      "100%": {
        transform: "scale(1)",
        opacity: 0.8
      }
    },
  }))(MaterialButton);
}
