import React, { Component, useState, useEffect } from 'react'
import { FormControl } from 'react-reactive-form';
import './NVLThankyouScreen.scss';
import { CountdownTimer } from '../../components/CountdownTimer'
import { MoveCounter } from '../../components/Thankyou/MoveCounter'
import { FormTitle } from '../../components/form/formTitle';
import { ConfirmationMessage } from '../../components/Thankyou/ConfirmationMessage';
import { Checklist } from '../../components/Thankyou/Checklist';
import { getEstimatorDetails } from 'core/services/movewithService'
import { Header } from 'components/header';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useStore } from '../../index';
import { ThankyouCard } from 'components/thankyouCard';
import { nvllogo , avatar } from "assets/images";
import { navigate } from '@reach/router';


declare global {
    interface Window { dataLayer: any; }
    interface Window { dataLayer: any; }
}
window.dataLayer = window.dataLayer || {};
window.fbq = window.fbq || {};

export interface NVLThankyouScreenProps {
    formControl?: FormControl;
    path?: string;
    progress?: number;
}

export interface NVLThankyouScreenState {
    header: any;
    bullets: any;
    cid: any;
    firstName: any;
    employeeImg: any;

}

const NVLThankyouScreen = ({ formControl, path, progress }: NVLThankyouScreenProps) => {
    const endNav = useStore((state: any) => state.endNav)

    const [firstName] = useState(localStorage.getItem("FirstName") || undefined);
    const [employeeImg] = useState(localStorage.getItem("EmployeeImg"));
    const moveData = JSON.parse(localStorage.getItem("movewith") || "{}");

    const getNavigatedPage = (moveWithObj: any) => {
        let pageUrl = '/';
        if (moveWithObj.MoveSize === ""){
            pageUrl = "/move?question=type";
        } else if(moveWithObj.MoveSizeDetail === ""){
            pageUrl = '/move?question=size';
        } else if (moveWithObj.MoveType === ""){
            pageUrl = "/move?question=move";
        } else if(moveWithObj.MoveDate === ""){
            pageUrl = "/move?question=date";
        } else if(moveWithObj.FirstName === "" || moveWithObj.Telephone === ""){
            pageUrl = "/move?question=confirm";
        }
        return pageUrl;
    }

    useEffect(() => {
        clearAllBodyScrollLocks();
        window.scrollTo(0, 0);
        const done: any = moveData.done;
        if(!done){
            const url: any = getNavigatedPage(moveData);
            navigate(url);
        }
        //   getEstimatorDetails(9);
           window.dataLayer.push({
             event: 'pageview',
             page: { path: "/thanks" }
         }) 
      
    }, []);

    const onLeftIconPress = () => {
        navigate("/");
        setTimeout(() => {
            endNav()
        }, 400);
    }

    return (
        <div className="thankyou">

            <Header
                // progress={100}
                // leftIcon={'arrow-left'}
                // leftText={'Home'}
                rightIcon={'phone'}
                rightText={'Call Us'}
               // onLeftIconPress={onLeftIconPress}
               
            />

            <div className="thankyou-title">
                <h1>The best move, period!</h1>
                <p>Trusted with over 1 million moves.</p>
            </div>
            <div className="inner-wrapper">
                <ThankyouCard
                // logoImg={nvllogo }
                moverInfo={"Aaron is calling you now!"}
                avatarImg={avatar}
                bottomstarRating={true}
                smallText={"Highest rated van line in the Customer Satisfaction Index for household goods relocations. We Make Moving Easy"}
                />
                <div className="copyright-text">
                    <p>Powered by Movingful</p>
                </div>

            </div>

        </div>
    )

}

export default NVLThankyouScreen;
