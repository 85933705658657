import React, { memo } from "react";
import { MoveWithCard, ImageCard } from "../card/templates";
import { ImagePage } from "components/pageTemplates/imagePage";
import { MoveWithPage } from "components/pageTemplates/moveWithPage";
import Chart from 'components/chart/Chart';

const TemplateSelector = ({ ...props }) => {

    const selectTemplate = (key: any) => {
        let view: any;
        switch (key) {
            case 'imageCard':
                view = <ImageCard {...props} />
                break;
            case 'moveWithCard':
                view = <MoveWithCard {...props} />
                break;
            case 'imagePage':
                //@ts-ignore
                view = <ImagePage {...props} />
                break;
            case 'form':
                //@ts-ignore
                view = <MoveWithPage {...props} />
                break;
            case 'kpiCard':
                //@ts-ignore
                view = <Chart {...props} />
                break;
        }
        return view;
    }

    return (
        selectTemplate(props['template'] || props['templateKey'])
    )
}

export default memo(TemplateSelector);