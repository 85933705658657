import React from "react";
import { FiPhone } from "react-icons/fi";
//import logo from "../../assets/images/AMS_Logo2.png";
import { nvllogo } from 'assets/images';
//@ts-ignore
import SimpleLineIcon from 'react-simple-line-icons';
import './Header.scss'
import { ProgressBar } from "components/progressBar";
import Nav from "screens/nav/nav";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";


export interface HeaderProps {
    backIcon?: boolean;
    progress?: number;
    // leftIcon?: any;
    leftIcon?: string;
    leftText?: string;
    rightIcon?: string;
    rightText?: string;
    onBackPress?(): void;
    onLeftIconPress?(): void;
    onRightIconPress?(): void;
    onLogoPress?(): void;
}

export interface HeaderState {
    phone: any
}

class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);
      //  var phoneNumber = localStorage.getItem("phone") == null ? "8442070741" : localStorage.getItem("phone");
      //  phoneNumber = "tel:" + phoneNumber;
        this.state = {
            phone: ""
        };
    }

    handleClick() {
        if (this.props.onBackPress) {
            this.props.onBackPress();
        }
    }
    onLeftIconClick() {
        if (this.props.onLeftIconPress) {
            this.props.onLeftIconPress();
        }
    }
    onRightIconClick() {
        if (this.props.onRightIconPress) {
            this.props.onRightIconPress();
        }
    }
    onLogoClick() {
        if (this.props.onLogoPress) {
            this.props.onLogoPress();
        }
    }
    componentDidMount(){
       
       
        const params = new URLSearchParams(window.location.search);
        var phone = params.get('phone') || ""; 
        //debugger;
        if(phone==="")
        {
            if(localStorage.getItem("office")==="NVL")
            {
            phone=localStorage.getItem("phone")||"8773507796"
            localStorage.setItem("phone",phone)
            }
            else{
                phone=localStorage.getItem("phone")||"8442070741"
                localStorage.setItem("phone",phone)
            }
        }
        
        if(localStorage.getItem("office")==="NVL")
            {
                phone="8773507796"
            }
            else{
                phone="8442070741"
            }
        
        var phoneNumber:any = "tel:" + phone;
        this.setState({phone:phoneNumber})
      //  alert("in header"+ phone)
    }

     formatPhoneNumber = (str:string) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');
        
        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };
      
        return null
      };

    render() {
        var USNumber = localStorage.getItem("phone")||"".match(/(\d{3})(\d{3})(\d{4})/)||"";
       // USNumber = "(" + USNumber[1] + ") " + USNumber[2] + "-" + USNumber[3];
        // console.log(this.formatPhoneNumber(localStorage.getItem("phone")||""))
        if (isMobile) {
        return (
                       <header className="fullcard-header">
                <div className="header-content">
                    {!this.props.backIcon ?
                        <div className="header-link price" role="link" onClick={() => this.onLeftIconClick()}>
                                <SimpleLineIcon name={`${this.props.leftIcon} link-icon`} />
                                <span className="link-label">{this.props.leftText}</span>
                        </div>
                        :
                        <div className="header-link back" role="link" onClick={() => this.handleClick()}>
                                <SimpleLineIcon name={`${this.props.leftIcon} link-icon`} />
                        </div>
                    }

                    <div className="header-logo" role="link" onClick={() => this.onLogoClick()}>
                        <img src={nvllogo} className="" alt="logo" />
                    </div>

                    {!this.props.onRightIconPress ?
                      <a href={this.state.phone} className="header-link call">
                        <SimpleLineIcon name={`${this.props.rightIcon} link-icon`} />
                        <span className="link-label"> {this.props.rightText}</span>
                        <span style={{display: 'none'}}>{this.state.phone}</span>
                      </a>
                       :
                       <div className="header-link" role="link" onClick={() => this.onRightIconClick()}>
                        <SimpleLineIcon name={this.props.rightIcon} />
                        <span className="link-label"> {this.props.rightText}</span>
                    </div>
                    }

                </div>


                {this.props.progress &&
                    <div className="progress-container">
                        <ProgressBar progress={this.props.progress} />
                    </div>
                }
            </header>
        
            
        );
            
           
            }
            else{
                return(
                    <header className="fullcard-header">
                    <div className="header-content">
                        {!this.props.backIcon ?
                            <div className="header-link price" role="link" onClick={() => this.onLeftIconClick()}>
                                    <SimpleLineIcon name={`${this.props.leftIcon} link-icon`} />
                                    <span className="link-label">{this.props.leftText}</span>
                            </div>
                            :
                            <div className="header-link back" role="link" onClick={() => this.handleClick()}>
                                    <SimpleLineIcon name={`${this.props.leftIcon} link-icon`} />
                            </div>
                        }
    
                        <div className="header-logo" role="link" onClick={() => this.onLogoClick()}>
                            <img src={nvllogo} className="" alt="logo" />
                        </div>
    
                        {!this.props.onRightIconPress ?
                                                   <h2>{this.formatPhoneNumber(this.state.phone)}</h2>
                           :
                           <div className="header-link" role="link" onClick={() => this.onRightIconClick()}>
                            <SimpleLineIcon name={this.props.rightIcon} />
                            <span className="link-label"> {this.props.rightText}</span>
                        </div>
                        }
    
                    </div>
    
    
                    {this.props.progress &&
                        <div className="progress-container">
                            <ProgressBar progress={this.props.progress} />
                        </div>
                    }
                </header>
                )
            }
    }
}

export default Header;
